import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../types/product.types';

type SearchType = 'searchforuser' | 'search' | 'quicksearch';

@Injectable({ providedIn: 'root' })
export class SearchService {
	constructor(private httpClient: HttpClient) {}

	public search({ query, productSize = 10, pageSize = 10, forUser = false }: SearchParams): Observable<SearchResult> {
		const searchType = forUser ? 'searchforuser' : 'search';
		const url = this.getBaseUrl(query, searchType, productSize, pageSize);
		return this.httpClient.get<SearchResult>(url);
	}

	public quicksearch({
		query,
		productSize = 6,
		pageSize = 6,
	}: Omit<SearchParams, 'forUser'>): Observable<QuickSearchResult> {
		const url = this.getBaseUrl(query, 'quicksearch', productSize, pageSize);
		return this.httpClient.get<QuickSearchResult>(url);
	}
	private getBaseUrl(query: string, searchType: SearchType, productSize: number, pageSize: number) {
		const params: Record<string, string | number> = {
			query,
			productSize,
			pageSize,
		};

		const urlParams = new URLSearchParams(params as unknown as keyof Record<string, string | number>).toString();

		const baseUrl =
			searchType === 'searchforuser' ? `/webapi/${searchType}?${urlParams}` : `/webapi/${searchType}?${urlParams}`;

		return baseUrl;
	}
}

interface SearchParams {
	query: string;
	productSize: number;
	pageSize: number;
	forUser?: boolean;
}

export interface SearchResult {
	ContentSearchResult: ContentSearchResult[];
	ProductContentSearchResult: Product[];
}

export interface QuickSearchResult {
	ContentSearchResult: ContentSearchResult[];
	ProductContentSearchResult: QuickSearchProductResponse[];
}

export interface ContentSearchResult {
	Header: string;
	LinkUrl: string;
	PublishDate: string;
}

export interface QuickSearchProductResponse {
	LinkUrl: string;
	ImageUrl: string;
	Description: string;
}
